<template>
  <div class="container">
    <div class="container-con">
      <!-- Header S -->
      <Head
        :backIsShow="true"
      />
      <!-- Header E -->
      <!-- search button S -->
      <section class="m-dxby-search-btn">
        <div class="m-dxby-search-input-wrap">
          <input
            v-model.trim="searchVal"
            type="search"
            @input="searchFn"
            @keyup="recordSearchValFn"
            placeholder="输入产品编号，如：5012"
            class="m-dxby-search-search"
          >
        </div>
      </section>
      <!-- search button E -->
      <!-- search  history S-->
      <section class="dxby-search-history">
        <span>搜索记录：</span>
        <div class="dxby-search-history-record">
          <ul v-if="searchHistoryData.length > 0">
            <li
              v-for="(item, key) in searchHistoryData"
              :key="key"
              @click="clickSearchFn(item.historyValue)"
            >{{item.historyValue}}</li>
          </ul>
          <i v-else>手机"搜索"按钮即可保存当前的搜索记录</i>
        </div>
        <a
          href=""
          class="dxby-clear-history"
          @click="clearHistRecordFn"
        >删除</a>
      </section>
      <!-- search  history E-->
      <!-- nav S -->
      <section :style="{height: addFixeds === true ? '70px' : 'auto'}">
        <section :class="addFixeds === true ? 'addFixed' : ''">
          <nav class="m-dxby-prd-nav">
            <div class="m-dxby-prd-nav-wrap">
              <ul>
                <li
                  v-for="(item, key) in navData"
                  :key="key"
                  @click="toProductPage(item.id, key)"
                >{{item.name}}</li>
              </ul>
            </div>
          </nav>
          <div class="m-dxby-nav-shadow"></div>
        </section>
      </section>
      <!-- nav E -->
      <!-- search list S -->
      <section
        class="m-dxby-prd-list"
        v-if="searchPrdData.length > 0"
      >
        <ul>
          <li
            v-for="(item, key) in searchPrdData"
            :key="key"
            @click="toDetails(item)"
          >
            <div class="m-dxby-prd-list-item">
              <div class="m-dxby-prd-list-item-img">
                <img :src="item.mainImg" :alt="item.name">
              </div>
              <div class="m-dxby-prd-list-item-text">
                <h3>{{item.sn}}</h3>
                <span>{{item.name}}</span>
              </div>
            </div>
          </li>
        </ul>
      </section>
      <section
        v-else
        class="dxby-noSearchData"
      >
        <img :src="searchImg" alt="">
        <span>空空如也，搜索试试(*^▽^*)</span>
      </section>
      <!-- search list E -->
    </div>
    <!-- 返回顶部 S -->
    <BackTop ref="isBackTop"/>
    <!-- 返回顶部 E -->
    <Foot/>
  </div>
</template>

<script>
import axios from 'axios'
import Head from '@/components/Head'
import Foot from '@/components/Foot'
import metaLable from '@/utils/metaLable'
import BackTop from '@/components/BackTop2'
import { hostApi } from '@/utils/requestApi'
import { OtherCommonFn } from '@/assets/js/common'
import { searchImg } from '@/utils/imageManage'

export default {
  name: 'search',
  metaInfo: {
    title: '大新布业 - 搜索页',
    meta: metaLable
  },
  data () {
    return {
      addFixeds: false,
      navData: [],
      searchVal: '',
      searchPrdData: [],
      searchImg,
      searchHistoryData: []
    }
  },
  components: {
    Head,
    Foot,
    BackTop
  },
  methods: {
    // 手机键盘搜索
    recordSearchValFn (e) {
      const code = e.keyCode
      if (code === 13) {
        if (this.searchVal === '') {
          this.searchPrdData = []
          return false
        }
        let obj = {
          historyValue: this.searchVal
        }
        this.searchHistoryData.unshift(obj)
        new OtherCommonFn().setStorage('saveSearchHistoryData', JSON.stringify(this.searchHistoryData))
        this.searchVal = ''
      }
    },
    // 点击历史搜索记录进行查询
    async clickSearchFn (val) {
      const res = await axios.post(`${hostApi}/goods/spu/list/sn`, { keywords: val })
      const { data } = res
      this.searchPrdData = data
    },
    // 删除搜索记录
    clearHistRecordFn () {
      new OtherCommonFn().removeStorage('saveSearchHistoryData')
    },
    // 商品搜索
    searchFn: new OtherCommonFn().debounce(async function () {
      let keywords = this.searchVal
      if (this.searchVal === '') {
        this.searchPrdData = []
        return
      }
      const res = await axios.post(`${hostApi}/goods/spu/list/sn`, { keywords })
      const { data } = res
      this.searchPrdData = data
    }),
    // 获取到导航栏数据
    async getNavData () {
      const res = await axios.get(`${hostApi}/goods/class/list`)
      const { data } = res
      this.navData = data
    },
    // 去到商品页
    toProductPage (pId, key) {
      new OtherCommonFn().setStorage('fromSearchPId', pId)
      new OtherCommonFn().setStorage('prdKey', key)
      this.$router.push({
        path: '/product',
        query: {
          fromTo: true
        }
      })
    },
    toDetails (item) {
      this.$router.push({
        path: '/productDetails',
        query: {
          id: item.id
        }
      })
    },
    // 浏览器滚动时元素距离顶部多少是显示和隐藏
    onscrollFn () {
      document.addEventListener('scroll', () => {
        let _topDistance = document.documentElement.scrollTop || document.body.scrollTop || window.pageYOffset
        _topDistance > 160 ? this.addFixeds = true : this.addFixeds = false
        _topDistance > 200 ? this.$refs.isBackTop.changeBackTopIsStatusFn(true) : this.$refs.isBackTop.changeBackTopIsStatusFn(false)
      })
    }
  },
  mounted () {
    this.getNavData()
    this.onscrollFn()
    if (this.searchVal === '') this.searchPrdData = []
    let historyRecordData = new OtherCommonFn().getStorage('saveSearchHistoryData')
    if (!historyRecordData) {
      historyRecordData = []
    }
    this.searchHistoryData = JSON.parse(historyRecordData)
  }
}
</script>

<style scoped lang="less">
@import '~@/assets/css/common.less';
.addFixed {
  .w(100%);
  .bg(rgba(255, 255, 255, .9));
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
}
.container-cons {
  height: 100%;
}
.m-dxby-prd-nav {
  margin: 0 10px;
  .m-dxby-prd-nav-wrap {
    min-width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    ul {
      min-width: 100%;
      display: flex;
      align-items: center;
      flex-wrap: nowrap;
      padding: 5px 0 16px;
      li {
        margin-right: 20px;
        padding: 8px 15px;
        font-size: 15px;
        box-shadow: 0px 2px 5px #666;
        margin-right: 12px;
        background-color: #fff;
        color: #999;
        border-radius: 5px;
        white-space: nowrap;
      }
      .active {
        color: #fff;
        background-color: #015462;
      }
    }
  }
}
.m-dxby-nav-shadow {
  .h(1px);
  box-shadow: 0 3px 3px #000;
  margin-bottom: 25px;
}
// list
.m-dxby-prd-list {
  margin: 15px 10px;
  overflow: hidden;
  ul {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    li {
      width: 33.3%;
      overflow: hidden;
      .m-dxby-prd-list-item {
        width: 92%;
        overflow: hidden;
        margin: 0 auto;
        .m-dxby-prd-list-item-img {
          width: 100%;
          height: 100px;
          overflow: hidden;
          border-radius: 5px;
          img {
            width: 100%;
            border-radius: 5px;
          }
        }
      }
      .m-dxby-prd-list-item-text {
        padding: 13px 0 28px;
        h3 {
          font-size: 16px;
          color: #000;
          display: block;
          .otx (1);
          text-align: center;
          margin-bottom: 5px;
        }
        span {
          max-height: 40px;
          font-size: 14px;
          color: #666;
          display: block;
          .otx (2);
          .lht(20px);
          .otx (2);
          text-align: center;
        }
      }
    }
  }
}
// 搜索按钮
.m-dxby-search-btn {
  margin: 15px 10px 0;
  .h(40px);
  .lht(40px);
  .fsize(14px);
  display: block;
  color: rgba(102, 102, 102, 0.6);
  display: block;
  border: 1px solid #ccc;
  border-radius: 30px;
  background: url(~@/assets/images/prodction/sousuo.png) no-repeat 97% center / 28px;
  overflow: hidden;
  .m-dxby-search-input-wrap {
    .wh(86%, 40px);
    .m-dxby-search-search {
      .wh(100%, 100%);
      border: 0;
      outline: none;
      display: block;
      text-indent: 20px;
      color: #333;
    }
  }
}
.dxby-noSearchData {
  margin: 80px 10px;
  text-align: center;
  img {
    .w(40px);
    opacity: .8;
    margin: 0 auto;
    display: block;
  }
  span {
    display: block;
    .fsize(14px);
    color: #666;
    margin-top: 10px;
  }
}
// 搜索记录
.dxby-search-history {
  margin: 10px 10px;
  overflow: hidden;
  display: flex;
  span {
    display: block;
    .h(17px);
    .lht(17px);
    .fsize(12px);
    color: #d81e03;
    font-weight: bold;
    position: relative;
    text-indent: 8px;
    &::before {
      .wh(2px, 100%);
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      .bg(#d81e03);
    }
  }
  .dxby-search-history-record {
    flex: 1;
    margin-right: 8px;
    ul {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      max-height: 33px;
      overflow: hidden;
      margin-top: 2px;
      li {
        flex: 0 0 auto;
        .fsize(12px);
        margin-left: 10px;
        margin-bottom: 5px;
        .rds(30px);
        color: #015462;
        font-weight: bold;
      }
    }
    i {
      .fsize(12px);
      color: #ccc;
      display: block;
      margin-top: 2px;
    }
  }
  .dxby-clear-history {
    .fsize(12px);
    color: #333;
    .h(18px);
    .lht(18px);
    display: block;
  }
}
</style>
